<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1>Комплексная модернизация
            <v-btn v-if="$user.role==='ROLE_VNII' || $user.role==='ROLE_ADMIN'"
                  @click="$router.push('/standard/vnii')" outlined style="float: right">
              просмотр отчетов по срезам
            </v-btn>
            <template v-if="$user.role!=='ROLE_CZN_TERRITORIAL'">
              <div>
                <h4>Уровень мониторинга:</h4>
                <v-radio-group v-model="monitoringLevel" row @change="initializeData()">
                  <v-radio label="Регионы" value="region"></v-radio>
                  <v-radio label="ЦЗН" value="czn"></v-radio>
                </v-radio-group>
              </div>
              <department-info v-if="monitoringLevel === 'czn'"
                   @depObj="department = $event; initializeData()"
              ></department-info>
              <template v-else-if="monitoringLevel === 'region'">
                <span v-if="$user.role==='ROLE_REGIONAL_AGENCY' || $user.role==='ROLE_CZN_MANAGER'">
                  {{ userRegion.name }}
                </span>
                <v-autocomplete v-else-if="$user.role==='ROLE_VNII' || $user.role==='ROLE_ADMIN'"
                    style="width: 500px; margin-top: 20px; margin-bottom: 76px"
                    label="Регион"
                    :items="regionList"
                    item-text="name"
                    return-object
                    v-model="userRegion"
                    @input="initializeData()"
                ></v-autocomplete>
              </template>
            </template>
            <template v-else>
              <span>{{ $user.department.name }}</span>
            </template>
          </h1>
<!--          {{$user.department.name}} <br> {{department}} <br>-->
          <div class="list">
            <table>
              <thead>
                <tr>
                  <th>Отчёты</th>
                  <th>Дата создания</th>
                  <th>Дата отправки</th>
                  <th>Статус</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="report in reportList" :key="report.id">
                  <td>
                    <router-link :to="'/standard/report/' + report.id">{{ report.monitoring.name }}</router-link></td>
                  <td>{{ report.createDate }}</td>
                  <td v-if="report.sendDate">{{ report.sendDate }}</td>
                  <td v-else>—</td>
                  <td>{{ reportStatuses[report.status] }}</td>
                </tr>
              </tbody>
            </table>
            <div v-if="reportList.length === 0" style="height: 48px; margin-top: 20px">
              Не найдены записи по комплексной модернизации
            </div>
         </div>
        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<script>
import departmentInfo from "@/components/elements/DepartmentInfo";
import api from "@/modules/api";

export default {
  name: 'StandardComponent',
  components: {
    departmentInfo
  },

  data: () => ({
    monitoringLevel: "region",
    configId: null,
    reportList: [],
    department: {name: null},
    regionList: [],
    userRegion: {},
    reportStatuses: {
      DRAFT: "Черновик",
      SENT: "Отправлено"
    },
    apiLoaded: false,
    errorText: null
  }),

  methods: {
    async initializeData(){
      await this.updateData();
    },

    async updateData() {
      let req,
          obj = {};
      if (this.monitoringLevel === "czn") {
        obj.type = "formdata_czn";
        obj.departmentName = this.department.name;
      } else if (this.monitoringLevel === "region") {
        obj.type = "formdata_region";
        obj.regionCode = this.userRegion.code;
      }
      // console.log('obj', obj)
      req = await api.postJson("/monitoring/typical/formData/getList", obj);
      if (req.ok) {
        this.reportList = req.payload;
         console.log('obj', obj)
        this.reportList.sort((a, b) => (a.createdDate > b.createdDate) ? 1 : -1);
        this.apiLoaded = true;
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    async getRegionList() {
      let req = await api.get("/getRegionsList");
      if (req.ok) {
        this.regionList = req.payload;
        // Сортировка по алфавиту
        this.regionList.sort((a, b) => (a.name > b.name ? 1 : -1));
        //console.log('region', this.userRegion);
      } else {
        console.log('get regionList error');
      }
    },
  },

  async beforeMount() {
    // this.configId = this.$route.params.configId
    if (this.$user.role==='ROLE_ADMIN' || this.$user.role==='ROLE_VNII') {
      await this.getRegionList();
    }
    this.apiLoaded = true;
    if (this.$user.role==='ROLE_CZN_MANAGER' ||
        this.$user.role==='ROLE_CZN_TERRITORIAL' ||
        this.$user.role==='ROLE_REGIONAL_AGENCY') {
      this.userRegion = this.$user.region;
    } else {
      this.userRegion = this.regionList[0];
    }
    if (this.$user.role==='ROLE_CZN_TERRITORIAL') {
      this.department = this.$user.department;
      this.monitoringLevel = 'czn';
    }
    await this.initializeData();
  }
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style lang="scss" scoped>
  .wrap > h1 > div {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 8px;
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #1C1C1F;
    }
    .v-input--selection-controls {
      margin-top: 0px;
      padding-top: 0px;
    }
    .v-input--selection-controls .v-radio > .v-label {
      font-size: 15px;
    }

    > div {
      width: 450px;
      &:first-child {
        margin-right: 77px;
      }
    }
  }

  .list {
    table {
      border-collapse: collapse;
      th {
        border-bottom: 1px solid #E8E8E9;
      }
    }
  }
</style>
